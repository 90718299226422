import React from 'react';
import { Card, Col, ConfigProvider, Divider, Flex, Layout, QRCode, Row, Space, Spin, Tag } from 'antd';
import { DingtalkOutlined, LinkOutlined, QqOutlined, WechatOutlined } from '@ant-design/icons';

const { Header, Footer, Content } = Layout;

const { Meta } = Card;

const GroupItem = ({ title, group_type, qrcode_url, group_number, tags, expire_time, hint }: { title: string, group_type: string, qrcode_url: string, group_number: number, tags: Array<string>, expire_time: number, hint: string}) => (
  <Col
    sm={{ flex: '100%' }}
    lg={{ flex: '50%' }}
    xl={{ flex: '33%' }}
  >
    {group_type === 'qq' ? (
      <Card 
        title={title}
        actions={[
          <a href={qrcode_url} target="_blank" rel="noreferrer"><LinkOutlined />加入群聊</a>
        ]}
        hoverable
      >
        <Meta
          avatar={<QqOutlined />}
          title="QQ群组"
        />
        <Divider />
        
        <Flex gap="4px 0" wrap>
          限制条件：
          {tags.map(tag => (
          <Tag color="blue">{tag}</Tag>
          ))}
        </Flex>
        {group_number > 0 ? (
          <><Divider />
          群号：{group_number}
          </>
        ) : null}
        {hint !== "" ? (
          <><Divider />
          {hint}
          </>
        ) : null}
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <QRCode type="svg" value={qrcode_url} color="blue" size={240} />
        </div>
      </Card>
    ) : group_type === 'wechat' ? (
      <Card 
        title={title}
        hoverable
        actions={[
          "请直接扫码加群"
        ]}
      >
        <Meta
          avatar={<WechatOutlined />}
          title="微信群组"
        />
        <Divider />
        <Flex gap="4px 0" wrap>
          限制条件：
          {tags.map(tag => (
          <Tag color="blue">{tag}</Tag>
          ))}
        </Flex>
        {expire_time > 0 ? (
          <><Divider />
          过期时间：{new Date(expire_time).toLocaleDateString()}
          </>
        ) : null}
        {hint !== "" ? (
          <><Divider />
          {hint}
          </>
        ) : null}
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <QRCode type="svg" value={qrcode_url} color="green" size={240} />
        </div>
      </Card>)
    : group_type === 'dingtalk' ? (
      <Card 
        title={title}
        hoverable
        actions={[
          <a href={qrcode_url} target="_blank" rel="noreferrer"><LinkOutlined />加入群聊</a>
        ]}
      >
        <Meta
          avatar={<DingtalkOutlined />}
          title="钉钉群组"
        />s
        <Divider />
        <Flex gap="4px 0" wrap>
          限制条件：
          {tags.map(tag => (
          <Tag color="blue">{tag}</Tag>
          ))}
        </Flex>
        {hint !== "" ? (
          <><Divider />
          {hint}
          </>
        ) : null}
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <QRCode type="svg" value={qrcode_url} color="blue" size={240} />
        </div>
      </Card>)
    : null}
    </Col>
);

const App: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [GroupListData, setGroupListData] = React.useState<any[]>([]);
  React.useEffect(() => {
    setLoading(true);
    fetch('/groups.json')
      .then(response => response.json())
      .then(data => {
        // store the data to GroupListData
        setGroupListData(data);
        setLoading(false);
      });
  }, []);
  return (
  <ConfigProvider>
    <Layout>
      <Header>
        <Space>
          <div style={{ color: 'white' }}>杨浦高级中学群组共享</div>
        </Space>
      </Header>
      <Content>
      <Spin spinning={loading}>
        <div style={{padding: "60px"}}>
          <Row gutter={[48, 64]}>
            {GroupListData.map((group, index) => (
              <GroupItem
                key={index}
                title={group.title}
                group_type={group.group_type}
                qrcode_url={group.qrcode_url}
                group_number={group.group_number}
                tags={group.tags}
                expire_time={group.expire_time}
                hint={group.hint}
              />
            ))}
          </Row>
        </div>
      </Spin>
      </Content>
      <Footer>version 0.1.0 Copyright 2024 ZianTT</Footer>
    </Layout>
  </ConfigProvider>
)};

export default App;
